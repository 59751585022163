<template>
  <div class="row access-code-section-outer">
    <div class="col-lg-8 col-md-8 col-12 login-banner-outer" v-if="this.basepathstatus == 1">
      <div class="
            d-flex
            flex-column
            min-vh-100
            justify-content-center
            align-items-center
            custom-baner-logo-height-mb
          ">
        <img v-if="this.clientBanerListImg.maa52 != null" :src="this.clientBanerListImg.maa52" class="access-bg-image"
          alt="baner-img" />
      </div>
    </div>
    <div class="col-lg-8 col-md-8 col-12 login-banner-outer" v-if="this.basepathstatus == 2">
      <div class="
            d-flex
            flex-column
            min-vh-100
            justify-content-center
            align-items-center
            custom-baner-logo-height-mb
          ">
        <img src="/assets/images/goa-banner.jpg" class="access-bg-image" alt="baner-img" />
      </div>
    </div>
    <div class="col-lg-8 col-md-8 col-12 login-banner-outer" v-if="this.basepathstatus == 3">
      <div class="
            d-flex
            flex-column
            min-vh-100
            justify-content-center
            align-items-center
            custom-baner-logo-height-mb
          ">
        <img src="/assets/images/cm-connect-banner.png" class="access-bg-image" alt="baner-img" />
      </div>
    </div>
    <div class="col-lg-8 col-md-8 col-12 login-banner-outer" v-if="this.basepathstatus == 4">
      <div class="
            d-flex
            flex-column
            min-vh-100
            justify-content-center
            align-items-center
            custom-baner-logo-height-mb
          ">
      </div>
    </div>
    <div class="col-lg-8 col-md-8 col-12 login-banner-outer" v-if="this.basepathstatus == 5">
      <div class="
            d-flex
            flex-column
            min-vh-100
            justify-content-center
            align-items-center
            custom-baner-logo-height-mb
          ">
        <img src="/assets/images/visiongroup-banner.jpg" class="access-bg-image" alt="baner-img" />
      </div>
    </div>
    <div class="col-lg-4 col-md-4 col-12 login-form-outer-border">
      <div class="d-flex justify-content-center vh-100">
        <div class="text-center mt-auto mb-auto" :style="{ width: '392px' }">
          <div class="login-header-image text-center mb-5 pb-5" v-if="this.basepathstatus == 1">
            <img src="/assets/images/meraneta-logo-black.png" class="img-fluid" alt="" width="146" />
          </div>
          <div class="login-header-image text-center mb-5 pb-5" v-if="this.basepathstatus == 2">
            <img src="/assets/images/visitor-connect-logo.png" class="img-fluid" alt="" width="300" />
          </div>
          <div class="login-header-image text-center mb-5 pb-5" v-if="this.basepathstatus == 3">
            <img src="/assets/images/CM-connect-black-logo.png" class="img-fluid" alt="" width="150" />
          </div>
          <div class="login-header-image text-center mb-5 pb-5" v-if="this.basepathstatus == 5">
            <img src="/assets/images/vision-logo.png" class="img-fluid" alt="" width="150" />
          </div>
          <div class="panel-card-access d-flex flex-column">
            <div class="access-header px-3 pt-2 pb-1">ACCESS CODE</div>
            <div class="access-sub-header">Welcome</div>
            <div class="d-flex flex-column px-3">
              <div class="form-floating mb-0 mt-3">
                <input type="text" v-model="accessvalue" maxlength="15" class="form-control" id="accesscodeInput"
                  autocomplete="off" placeholder="Access Code">
                <label for="accesscodeInput">Enter Access Code</label>
              </div>
              <div class="custom-error" v-if="v$.accessvalue.$error">
                {{ v$.accessvalue.$errors[0].$message }}
              </div>
              <div class="my-4">
                <button class="btn access-submit-btn" type="submit" @click="checkuseraccesscode()" :disabled="showloader">
                  <span v-if="!showloader">Submit</span>
                  <div class="
                    spinner-border
                    text-light
                    custom-spinner-loader-btn
                  " role="status" v-if="showloader"></div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { required, helpers, maxLength } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import ApiService from "../service/ApiService";

export default {
  data() {
    return {
      v$: useValidate(),
      accessvalue: "",
      showloader: false,
      clientBanerListImg: [],
      clientimg: '',
      visitorurlpath: '',
      meranetaurlpath: '',
      basepathstatus: 0,
      baseUrl: window.location.origin,
    };
  },
  watch: {
    accessvalue() {
      this.v$.$validate();
    },
  },
  validations() {
    return {
      accessvalue: {
        required: helpers.withMessage("Please enter access code", required),
        maxLength: helpers.withMessage(
          "Password should be max 15 length",
          maxLength(15)
        ),
      },
    };
  },

  mounted() {
    this.clientdetail();
    if (localStorage.user_reference) {
      this.$router.push("/dashboard");
    }
    else if (!localStorage.client_info) {
      this.$router.push("/");
    }
    if (this.baseUrl == 'https://vc.nextedgecivictech.in') {
      this.basepathstatus = 2;
    } else if (this.baseUrl == 'https://demo.cm-connect.in'){
      this.basepathstatus = 3;
    } else if (this.baseUrl == 'https://test.nextedgecivictech.in'){
      this.basepathstatus = 4;
    } else if (this.baseUrl == 'https://vision.nextedgecivictech.in'){
      this.basepathstatus = 5;
    } else {
      this.basepathstatus = 1;
    }
  },

  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },

  methods: {
    checkuseraccesscode() {
      this.v$.$validate();
      let fields = {};
      fields["access_code"] = this.accessvalue;
      if (!this.v$.$error) {
        this.showloader = true;
        this.ApiService.checknetaaccesscode(fields).then((items) => {
          if (items.success === true) {
            this.showloader = false;
            localStorage["client_info"] = JSON.stringify(
              items.data.client_info
            );
            localStorage["user_reference"] = items.data.user_reference;
            window.location.href = "/dashboard"
            setTimeout(() => {
              this.v$.$reset();
            }, 0);
          } else {
            this.showloader = false;
            this.$toast.open({
              message: items.message,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
          }
        });
      }
    },
    clientdetail() {
      this.ApiService.clientdetail().then((data) => {
        if (data.success === true) {
          this.clientBanerListImg = data.records.client_info;
        }
      });
    },
  },
};
</script>
<style scoped>
.access-code-section-outer {
  background: transparent linear-gradient(43deg, #E6E9F8 0%, #E9ECF9 38%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
  height: 100vh;
}

.access-code-section-outer .panel-card-access {
  border: 1.5px solid #E4E4E4;
  border-radius: 6px;
  text-align: center;
  background-color: #ffffff;
  padding: 1rem;
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
}

.access-code-section-outer .access-header {
  margin-top: -3rem;
  color: #ffffff;
  border: 1.5px solid #E4E4E4;
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
  font-size: 30px;
  line-height: 1;
  background: #f76100;
  font-family: 'AcuminPro-Bold';
}

.access-code-section-outer .access-sub-header {
  font-size: 1.5rem;
  margin: 1.5rem 0 1rem 0;
  font-family: 'AcuminPro-SemiBold';
  line-height: 1.2;
}

.access-code-section-outer .access-submit-btn {
  width: 100%;
  background: #5266E5 0% 0% no-repeat padding-box;
  border-color: #5266E5;
  border-radius: 4px;
  letter-spacing: 0.26px;
  color: #FFFFFF;
  text-align: center;
  font-size: 13px;
  line-height: 20px;
  padding: 9px 11px;
  font-family: 'AcuminPro-Regular';
  margin-top: 4px;
}

.access-code-section-outer .access-submit-btn:focus,
.access-code-section-outer .access-submit-btn:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

.access-code-section-outer .form-floating .form-control {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #c0c3c9;
  border-radius: 4px;
  font-family: 'AcuminPro-Regular';
  font-size: 14px;
  letter-spacing: 0.42px;
  color: #0B294B;
}

.access-code-section-outer .form-floating .form-control:focus {
  box-shadow: none !important;
  outline: 0;
  text-decoration: none;
  background-color: #fff;
  border-color: #80bdff !important;
}

.access-code-section-outer .form-floating .form-control:hover {
  border: 1px solid #bcbec1;
}

.access-code-section-outer .custom-error {
  color: #de2525;
  font-family: 'AcuminPro-Regular';
  font-size: 13px;
  text-align: left;
  letter-spacing: 0.52px;
  line-height: 20px;
}

.access-bg-image {
  width: 100%;
  height: 100vh;
}
</style>
